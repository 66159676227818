export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3433733026926822',
  agentpwd : '3433@6822$',
  apikey : '53461c58df08c7157546867341f54200',
  url : 'https://sofina.afrikpay.com',
  id : 'sofina.afrikpay.com',
  accepturl : 'https://sofina.afrikpay.com/payment/web/success',
  cancelurl : 'https://sofina.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};